import { OrderConfirmationLaybyDetails } from "@models/OrderConfirmation/OrderConfirmationDataModel";

export interface LaybyQuote {
    bracketName: string;
    installmentFrequency: number;
    installmentCount: number;
    depositAmount: number;
    paymentAmount: number;
    totalAmount: number;
}


export const mapTransactionConfirmationToLaybyQuote = (laybyQuote: OrderConfirmationLaybyDetails) =>{
    if(laybyQuote == undefined){
        return undefined;
    }else{
        return  {                            
            bracketName: laybyQuote.DaysInPeriod == 7 ? "Weekly" : (laybyQuote.DaysInPeriod == 14 ? "Fortnightly" : "Monthly"),
             installmentFrequency: laybyQuote.DaysInPeriod,
             installmentCount: laybyQuote.NumPeriods,
             depositAmount: laybyQuote.DepositAmount,
             paymentAmount: laybyQuote.MinRegularAmount,
             totalAmount: laybyQuote.LaybyAmount
         };
    }
}