import { BasketLineResponseModel } from "./BasketLineResponseModel";
import { OrderConfirmationDataModel } from "@models/OrderConfirmation";
import { TransactionItemResponse } from "@models/OrderConfirmation/OrderConfirmationDataModel";
import { getVehicleTypeById, getPlateSize, mapPlateSizeFromTrnasaction } from "@utils/Helpers";
import { BasketPlateCaptionResponseModel } from "./BasketCaptionResponseModel";

export interface BasketResponseModel {
    basketId: string,
    basketLines: BasketLineResponseModel[],
    summary: BasketResponseSummary,
    loading: boolean,
    success: boolean
}


export interface BasketResponseSummary {
    itemTotal: number
    discount: number
    finalTotal: number
    gst: number
    promotionCode?: string | undefined
    allowPaymentLayby: boolean
}

export const mapTransactionResponseToBasketResponseModel = (transaction: OrderConfirmationDataModel) => {

    var result: BasketResponseModel = {
        basketId: transaction.BasketId,
        basketLines: transaction.Items.map(item => mapTransactionItemToBasketLine(item, transaction.IsGift)),
        summary: calculateBasketSummary(transaction),
        loading: false,
        success: false
    }
    return result;
}
export const calculateBasketSummary = (transaction: OrderConfirmationDataModel): BasketResponseSummary => {
    let itemTotal = 0;
    let finalTotal = 0;
    let gst = 0;
    let promoCode = transaction.Coupon;
    let allowPaymentLayby = true;
    transaction.Items.map((item) => {
        if (item.DiscountedAmountExGST != undefined && item.DiscountedGST != undefined && item.DiscountedAmountExGST != 0 && item.DiscountedGST != 0) {
            finalTotal += item.DiscountedAmountExGST + item.DiscountedGST;
            gst += item.DiscountedGST;
        } else {
            finalTotal += item.PriceExGst + item.PriceGst;
            gst += item.PriceGst;
        }
        itemTotal += item.PriceExGst + item.PriceGst
    });
    return {
        itemTotal,
        discount: itemTotal - finalTotal,
        finalTotal: finalTotal,
        gst: gst,
        promotionCode: promoCode,
        allowPaymentLayby: allowPaymentLayby
    }
}
// Check undefined value use != instead of !== to ignore data type, just check if undefined and null
const mapTransactionItemToBasketLine = (item: TransactionItemResponse, isGift: boolean): BasketLineResponseModel => {
    var frontPlate = {
        rangeName: item.PlateRangeName,
        rangeId: 0,
        designId: item.PlateDesignId,
        designName: item.PlateDesignName,
        foregroundColorId: item.ForegroundColourId,
        foregroundColorName: '',
        size: mapPlateSizeFromTrnasaction(item.PlateSizeId, item.PlateSizeName)
    };
    var backPlate = item.DualPlateDesignId != undefined && item.DualPlateSizeId != undefined ? {
        rangeName: item.DualPlateRangeName,
        rangeId: 0,
        designId: item.DualPlateDesignId,
        designName: item.DualPlateDesignName,
        foregroundColorId: item.DualPlateForegroundColourId == undefined ? 0 : item.DualPlateForegroundColourId,
        foregroundColorName: '',
        size: mapPlateSizeFromTrnasaction(item.DualPlateSizeId, item.DualPlateSizeName)
    } : frontPlate;
    const topCaption: BasketPlateCaptionResponseModel | undefined = item.CaptionTopColourId == undefined ? undefined : {
        captionText: item.CaptionTopText,
        captionColourId: item.CaptionTopColourId,
        captionColourName: ''
    };
    const bottomCaption: BasketPlateCaptionResponseModel | undefined = item.CaptionBottomColourId == undefined ? undefined : {
        captionText: item.CaptionBottomText,
        captionColourId: item.CaptionBottomColourId,
        captionColourName: ''
    };
    return {
        basketLineId: '',
        combination: item.Combination,
        combinationLayout: item.CombinationFormat,
        transactionTypeName: item.TransactionType,
        transactionTypeId: item.TransactionTypeId,
        vehicleTypeId: item.VehicleTypeId,
        vehicleTypeName: item.VehicleTypeId == undefined ? "" : getVehicleTypeById(item.VehicleTypeId).name,
        frontPlate: frontPlate,
        backPlate: backPlate,
        topCaption: topCaption,
        bottomCaption: bottomCaption,
        stillAvailable: true,
        isGift: isGift,
        priceInclGst: item.PriceExGst + item.PriceGst,
        gst: item.PriceGst,
        discountedGst: item.DiscountedGST == undefined ? item.PriceGst : item.DiscountedGST,
        discountedPriceInclGst: (item.DiscountedAmountExGST == undefined ? item.PriceExGst : item.DiscountedAmountExGST) + (item.DiscountedGST == undefined ? item.PriceGst : item.DiscountedGST),
        isDiscounted: item.DiscountedAmountExGST != undefined && item.DiscountedGST != undefined,
        OriginalQuoteAmount: item.OriginalQuoteAmount
    }
}