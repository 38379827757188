import React from "react"
import { Paragraph1, H6, Paragraph2, H2 } from "@styles/Global.styles"
import { Grid, Icon, Input, List } from "semantic-ui-react"
import { LaybyPaymentListContent, LaybyPaymentSummaryRow, LaybyPaymentSummaryRowBordered } from "./MakePayment.styles";
import { Checkout } from "@models/Checkout/Checkout";
import { LaybyQuote } from "@models/index";
import { LaybyService } from "@services/index";
import { BasketResponseModel } from "@models/Basket/BasketResponseModel";
import moment from "moment";
import { KpFormDatePickerIcon, KpFormDatePickerInput, KpFormDayPickerInput, KpFormDayPickerInputIconContiner } from "@styles/Global.forms";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { OrderConfirmationLaybyDetails } from "@models/OrderConfirmation/OrderConfirmationDataModel";

interface LaybyPaymentViewOnlyProps {
    apiUrl: string
    basket: BasketResponseModel
    laybyDetails: OrderConfirmationLaybyDetails
    laybyInfo: string
}
interface LaybyPaymentViewOnlyState {
}

class LaybyPaymentViewOnly extends React.Component<LaybyPaymentViewOnlyProps, LaybyPaymentViewOnlyState>{


    formatDate = (date: Date, format: string, locale: string) => {
        return moment(date).format('DD/MM/yyyy');
    }

    calculatePaymentSurcharge = (amount: number) => {
        return amount + (amount * 0.019);
    }


    render() {

        return (
            <Grid>
                <Grid.Column width={16}>
                    <Paragraph1 dangerouslySetInnerHTML={{ __html: this.props.laybyInfo }} />
                </Grid.Column>
                <Grid.Column width={16}>
                    <H6>Your Layby Frequency</H6>
                    <List horizontal>
                        <List.Item>
                            <LaybyPaymentListContent>
                                {this.props.laybyDetails.BracketName}
                            </LaybyPaymentListContent>
                        </List.Item>
                    </List>
                </Grid.Column>
                <Grid.Column width={16}>
                    <Grid>
                        <LaybyPaymentSummaryRow>
                            <Grid.Column width='16'>
                                <H6>Layby Summary</H6>
                            </Grid.Column>
                        </LaybyPaymentSummaryRow>
                        <LaybyPaymentSummaryRow>
                            <Grid.Column width='12' textAlign='left'>
                                <Paragraph2>
                                    Layby Order Total (incl. $ {(this.props.basket.summary.finalTotal * 0.019).toFixed(2)} processing fee*)
                                    </Paragraph2>
                            </Grid.Column>
                            <Grid.Column width='4' textAlign='right'>
                                <Paragraph2>
                                    ${this.calculatePaymentSurcharge(this.props.basket.summary.finalTotal).toFixed(2)}
                                </Paragraph2>
                            </Grid.Column>
                        </LaybyPaymentSummaryRow>
                        <LaybyPaymentSummaryRowBordered>
                            <Grid.Column width='12' textAlign='left'>
                                <Paragraph2>
                                    <strong>First Payment (Deposit) Due Now</strong>
                                </Paragraph2>
                            </Grid.Column>
                            <Grid.Column width='4' textAlign='right'>
                                <Paragraph2>
                                    <strong>${this.calculatePaymentSurcharge(this.props.laybyDetails.DepositAmount).toFixed(2)}</strong>
                                </Paragraph2>
                            </Grid.Column>
                        </LaybyPaymentSummaryRowBordered>
                        <LaybyPaymentSummaryRow></LaybyPaymentSummaryRow>
                        <LaybyPaymentSummaryRow>
                            <Grid.Column width='16' textAlign='left'>
                                <Paragraph2 color='#707070'>
                                    {this.props.laybyDetails.NumPeriods} x ${this.calculatePaymentSurcharge(this.props.laybyDetails.DepositAmount).toFixed(2)} Remaining Payments (Due every {this.props.laybyDetails.DaysInPeriod} days)
                                </Paragraph2>
                                {
                                    this.props.laybyDetails.FinalAmount !== this.props.laybyDetails.DepositAmount &&
                                    <Paragraph2 color='#707070'>
                                        Note: The final payment amount is  ${this.calculatePaymentSurcharge(this.props.laybyDetails.DepositAmount).toFixed(2)}.
                                    </Paragraph2>
                                }
                            </Grid.Column>
                        </LaybyPaymentSummaryRow>
                        <LaybyPaymentSummaryRow>
                            <Grid.Column width='6' textAlign='left'>
                                <Paragraph2 color='#707070'>
                                    Next Payment: {moment(this.props.laybyDetails.NextPaymentDue).format('DD MMMM YYYY')}
                                </Paragraph2>
                            </Grid.Column>
                            <Grid.Column width='10' textAlign='left'>
                                <Paragraph2 color='#707070'>
                                    Estimated Completion: {moment(this.props.laybyDetails.EstimatedCompletionDate).format('DD MMMM YYYY')}
                                </Paragraph2>
                            </Grid.Column>
                        </LaybyPaymentSummaryRow>
                    </Grid>
                </Grid.Column>
            </Grid>
        )
    }
}


export default LaybyPaymentViewOnly