import React, { SyntheticEvent } from "react"
import { Button, Grid, Checkbox, CheckboxProps, Responsive, GridColumn, GridRow, Icon } from "semantic-ui-react"
import {
  PaymentOptionButton,
  SectionHeader,
  LaybyPaymentContainer,
  TermsConditionsCheckbox,
  PaymentOptionRadioButton,
  PaymentOptionRadio,
  AfterpayButton,
  RadioButtonWrap,
} from "./MakePayment.styles"
import {
  SummaryActionGrid,
  SummaryActionColumn,
} from "@components/ShoppingCart/ShoppingCart.styles"
import { KpButton } from "@elements/index"
import theme from "@styles/theme"
import { PaymentService } from "@services/PaymentService"
import { navigate } from "gatsby"
import { OrderReviewSummaryData } from "@components/OrderReviewDetails/mapper"
import InfoModal from "@components/InfoModal/InfoModal"
import { BasketResponseModel } from "@models/Basket/BasketResponseModel"
import { Paragraph1, Paragraph2, SummaryGroupLabel } from "@styles/Global.styles"
import { Legal } from "@styles/Global.styles"
import { CheckCombinationService } from "@services/CheckCombinationService"
import LaybyPaymentViewOnly from "./LaybyPaymentViewOnly"
import { OrderConfirmationLaybyDetails } from "@models/OrderConfirmation/OrderConfirmationDataModel"
import moment from "moment"
import { PaymentOptions } from "@utils/Constant"
import AfterpayLogo from "@assets/svgs/AfterpayLogo"
import { getCurrentCheckout, saveAllowAfterToLocalStorage } from "@redux/localStorage/checkout"
import { CheckoutSelectionCardIndicatorPayment } from "@components/CheckoutSelectionCard/CheckoutSelectionCard.styles"
import { checkPromoService, validatePromoService } from "@services/BasketService"
import { CDCard } from "./CDCard"
import { Gem } from "./Gem"
import { Afterpay } from "./Afterpay"

interface RetryPaymentProps {
  apiUrl: string
  basket: BasketResponseModel
  data: OrderReviewSummaryData
  transactionId?: number
  guid?: string
  isLaybySelected: boolean
  deliveryType: number
  laybyQuote?: OrderConfirmationLaybyDetails
  orderCreatedDate: string
  previousPaymentType: number
  swithPaymentOption: (paymentType: number) => void
  manufactureNow: boolean
}
interface RetryPaymentState {
  processing: boolean
  error: string
  onErrorModalClose: Function
  paymentType: number
  showError: boolean
  ccManufaucture: boolean
  ccAgreeTC: boolean
  a2aAgreeTC: boolean
  afterpayAgreeTC: boolean
  dealerTC: boolean,
  dealerCustomerTC: boolean,
  dealerAuthorityTC:boolean,
  dealerPaymentRequestTC: boolean,
  disableAfterpayPayment: boolean,
  gemAgreeTC: boolean
}
/* eslint-disable  jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
class MobileRetryPayment extends React.Component<
  RetryPaymentProps,
  RetryPaymentState
  > {
  state = {
    processing: false,
    error: "",
    onErrorModalClose: () => {
      navigate(`/payment-failure/?err=${this.state.error}`)
    },
    ccManufaucture: false,
    ccAgreeTC: false,
    a2aAgreeTC: false,
    showError: false,
    afterpayAgreeTC: false,
    paymentType: this.props.previousPaymentType,
    dealerTC: false,
    dealerCustomerTC: false,
    dealerAuthorityTC: false,
    dealerPaymentRequestTC: false,
    disableAfterpayPayment: false,
    gemAgreeTC: false
  }

  componentDidMount() {
    if(this.props.basket.summary.promotionCode){
      const isAfterpayAvailable = this.props.basket.summary.itemTotal < 2000;
      const isDiscounted = this.props.basket.summary.discount !== 0
      checkPromoService(this.props.apiUrl, this.props.basket.summary.promotionCode, this.props.basket.basketId).then((data: any) => {
        if (data.Success) {
          if(data.Data.toLowerCase().includes("afterpay")) {
            saveAllowAfterToLocalStorage('true')
            this.setState({disableAfterpayPayment: false})
          } else {
            saveAllowAfterToLocalStorage('')
            this.setState({disableAfterpayPayment: true})
          }
        } else{
          saveAllowAfterToLocalStorage('')
          this.setState({disableAfterpayPayment: !isAfterpayAvailable || isDiscounted})
        }
      })
    }
  }

  changeCCManufacture = (event: SyntheticEvent, data: CheckboxProps) => {
    const isChecked = data.checked ? data.checked : false
    this.setState({
      ccManufaucture: isChecked,
      showError: this.state.showError
        ? this.showCheckboxError(
          this.state.ccAgreeTC,
          isChecked,
          this.state.a2aAgreeTC,
          this.state.afterpayAgreeTC,
          this.state.paymentType,
          this.state.dealerTC,
          this.state.dealerCustomerTC,
          this.state.dealerAuthorityTC,
          this.state.dealerPaymentRequestTC,
          this.state.gemAgreeTC
        )
        : this.state.showError,
    })
  }
  changeCCAgreeTC = (event: SyntheticEvent, data: CheckboxProps) => {
    const isChecked = data.checked ? data.checked : false
    this.setState({
      ccAgreeTC: isChecked,
      showError: this.state.showError
        ? this.gemAgreeTC(
          isChecked,
          this.state.ccManufaucture,
          this.state.a2aAgreeTC,
          this.state.afterpayAgreeTC,
          this.state.paymentType,
          this.state.dealerTC,
          this.state.dealerCustomerTC,
          this.state.dealerAuthorityTC,
          this.state.dealerPaymentRequestTC,
          this.state.gemAgreeTC
        )
        : this.state.showError,
    })
  }
  changeA2AAgreeTC = (event: SyntheticEvent, data: CheckboxProps) => {
    const isChecked = data.checked ? data.checked : false
    this.setState({
      a2aAgreeTC: isChecked,
      showError: this.state.showError
        ? this.showCheckboxError(
          this.state.ccAgreeTC,
          this.state.ccManufaucture,
          isChecked,
          this.state.afterpayAgreeTC,
          this.state.paymentType,
          this.state.dealerTC,
          this.state.dealerCustomerTC,
          this.state.dealerAuthorityTC,
          this.state.dealerPaymentRequestTC,
          this.state.gemAgreeTC
        )
        : this.state.showError,
    })
  }

  changeGemAgreeTC = (event: SyntheticEvent, data: CheckboxProps) => {
    const isChecked = data.checked ? data.checked : false
    this.setState({
      gemAgreeTC: isChecked,
      showError: this.state.showError
        ? this.showCheckboxError(
          this.state.ccAgreeTC,
          this.state.ccManufaucture,
          this.state.a2aAgreeTC,
          this.state.afterpayAgreeTC,
          this.state.paymentType,
          this.state.dealerTC,
          this.state.dealerCustomerTC,
          this.state.dealerAuthorityTC,
          this.state.dealerPaymentRequestTC,
          isChecked
        )
        : this.state.showError,
    })
  }
  changeAfterpayAgreeTC = (event: SyntheticEvent, data: CheckboxProps) => {
    const isChecked = data.checked ? data.checked : false
    this.setState({
      afterpayAgreeTC: isChecked,
      showError: this.state.showError
        ? this.showCheckboxError(
          this.state.ccAgreeTC,
          this.state.ccManufaucture,
          this.state.a2aAgreeTC,
          isChecked,
          this.state.paymentType,
          this.state.dealerTC,
          this.state.dealerCustomerTC,
          this.state.dealerAuthorityTC,
          this.state.dealerPaymentRequestTC,
          this.state.gemAgreeTC
        )
        : this.state.showError,
    })
  }

  changeDealerTC = (event: SyntheticEvent, data: CheckboxProps) => {
    const isChecked = data.checked ? data.checked : false
    this.setState({
      dealerTC: isChecked,
      showError: this.state.showError
        ? this.showCheckboxError(
          this.state.ccAgreeTC,
          this.state.ccManufaucture,
          this.state.a2aAgreeTC,
          this.state.afterpayAgreeTC,
          this.state.paymentType,
          isChecked,
          this.state.dealerCustomerTC,
          this.state.dealerAuthorityTC,
          this.state.dealerPaymentRequestTC,
          this.state.gemAgreeTC
        )
        : this.state.showError,
    })
  }

  changeDealerCustomerTC = (event: SyntheticEvent, data: CheckboxProps) => {
    const isChecked = data.checked ? data.checked : false
    this.setState({
      dealerCustomerTC: isChecked,
      showError: this.state.showError
        ? this.showCheckboxError(
          this.state.ccAgreeTC,
          this.state.ccManufaucture,
          this.state.a2aAgreeTC,
          this.state.afterpayAgreeTC,
          this.state.paymentType,
          this.state.dealerTC,
          isChecked,
          this.state.dealerAuthorityTC,
          this.state.dealerPaymentRequestTC,
          this.state.gemAgreeTC
        )
        : this.state.showError,
    })
  }

  changeDealerAuthorityTC = (event: SyntheticEvent, data: CheckboxProps) => {
    const isChecked = data.checked ? data.checked : false
    this.setState({
      dealerAuthorityTC: isChecked,
      showError: this.state.showError
        ? this.showCheckboxError(
          this.state.ccAgreeTC,
          this.state.ccManufaucture,
          this.state.a2aAgreeTC,
          this.state.afterpayAgreeTC,
          this.state.paymentType,
          this.state.dealerTC,
          this.state.dealerCustomerTC,
          isChecked,
          this.state.dealerPaymentRequestTC,
          this.state.gemAgreeTC
        )
        : this.state.showError,
    })
  }

  changeDealerPaymentRequestTC = (event: SyntheticEvent, data: CheckboxProps) => {
    const isChecked = data.checked ? data.checked : false
    this.setState({
      dealerPaymentRequestTC: isChecked,
      showError: this.state.showError
        ? this.showCheckboxError(
          this.state.ccAgreeTC,
          this.state.ccManufaucture,
          this.state.a2aAgreeTC,
          this.state.afterpayAgreeTC,
          this.state.paymentType,
          this.state.dealerTC,
          this.state.dealerCustomerTC,
          this.state.dealerAuthorityTC,
          isChecked,
          this.state.gemAgreeTC
        )
        : this.state.showError,
    })
  }

  paymentExpired = () => {
    const createdDate = new Date(this.props.orderCreatedDate).getTime();
    const expiredDate = createdDate + 1800000;

    const now = new Date();
    console.log(now.getTime());
    console.log(expiredDate);
    if (expiredDate < now.getTime()) {
      return true;
    }
    return false;
  }
  retryPayment = () => {
    if (!this.paymentExpired()) {
      if (
        !this.showCheckboxError(
          this.state.ccAgreeTC,
          this.state.ccManufaucture,
          this.state.a2aAgreeTC,
          this.state.afterpayAgreeTC,
          this.state.paymentType,
          this.state.dealerTC,
          this.state.dealerCustomerTC,
          this.state.dealerAuthorityTC,
          this.state.dealerPaymentRequestTC,
          this.state.gemAgreeTC
        )
      ) {
        this.setState({
          processing: true,
          showError: false,
        })
        const containsUpgradeRedesign = this.props.basket.basketLines.filter(b => b.transactionTypeId !== 1).length > 0;
        if (this.state.paymentType == PaymentOptions.Account2Account && containsUpgradeRedesign || this.state.paymentType == PaymentOptions.DealerPaymentRequest ) {
          navigate('/order-confirmation/?success=1&rcpt=' + this.props.transactionId + "&key=" + this.props.guid, { replace: true });
        } else {
          this.goToPayment();
        }
      } else {
        this.setState({
          showError: true,
        })
      }
    } else {
      navigate(`/payment-failure/?err=retry_session_expired`)
    }

  }

  goToPayment = () => {
    var basketId = this.props.basket.basketId;
    var amount = this.props.isLaybySelected ? this.props.laybyQuote?.DepositAmount : undefined;
    var transactionId = this.props.transactionId;

    this.setState({
      processing: true,
      showError: false,
    })
    if (this.state.paymentType == PaymentOptions.Afterpay) {
      PaymentService.getPaymentFromAfterpayRequest(
        this.props.apiUrl,
        basketId,
        amount
      ).then(response => {
        if (response && response.StatusCode == 201) {
          window.location.replace(response.Result.redirectCheckoutUrl);
        } else {
          PaymentService.getPaymentFromAfterpayRequestByTransactionId(
            this.props.apiUrl,
            transactionId,
            amount
          ).then(response => {
            if (response && response.StatusCode == 201){
              window.location.replace(response.Result.redirectCheckoutUrl);
            } else {
              this.setState({
                error: response.Message,
                processing: false,
              })
            }
          })

        }
      })
    } else {
      var paymentType = this.state.paymentType
      if(paymentType === PaymentOptions.Gem){
        paymentType = PaymentOptions.CreditCard
      }

      PaymentService.paymentOnlineRequest(
        this.props.apiUrl,
        basketId,
        paymentType,
        amount
      ).then(response => {
        if (response && response.url) {
          window.location.replace(response.url)
          // }
        } else {
          this.setState({
            error: "Payment request failed.",
            processing: false,
          })
        }
      }).catch(err => {
        const message =
          "Payment failed. Please contact customer service by phone or via livechat."
        console.log(err)
        this.setState({ error: message, processing: false })
      })
    }

  }

  switchPaymentType = (paymentType: number) => {
    this.setState({
      ccManufaucture: false,
      ccAgreeTC: false,
      a2aAgreeTC: false,
    })
    this.setState({ paymentType: paymentType });
    this.props.swithPaymentOption(paymentType);
  }

  showCheckboxError = (
    ccAgreeTC: boolean,
    ccManufaucture: boolean,
    a2aAgreeTC: boolean,
    afterpayAgreeTC: boolean,
    selectedPaymentOption: number,
    dealerTC: boolean,
    dealerCustomerTC: boolean,
    dealerAuthorityTC: boolean,
    dealerPaymentRequestTC: boolean,
    gemAgreeTC: boolean
  ): boolean => {
    let showError = false
    const checkout = getCurrentCheckout()
    if (selectedPaymentOption === PaymentOptions.CreditCard) {
      if (checkout.isDealerOrder){
          if (this.props.deliveryType !== 1) {
            showError = !dealerTC
          } else {
            showError = !dealerTC || !ccManufaucture
          }
      } else{
        if (this.props.deliveryType !== 1) {
          showError = !ccAgreeTC
        } else {
          showError = !ccAgreeTC || !ccManufaucture
        }
      }
    }
    else if (selectedPaymentOption === PaymentOptions.Account2Account) {
      if (this.props.deliveryType !== 1) {
        showError = !a2aAgreeTC
      } else {
        showError = !a2aAgreeTC || !ccManufaucture
      }
    } else if (selectedPaymentOption === PaymentOptions.Gem) {
      if (checkout.isDealerOrder){
          if (this.props.deliveryType !== 1) {
            showError = !dealerTC
          } else {
            showError = !dealerTC || !ccManufaucture
          }
      } else{
        if (this.props.deliveryType !== 1) {
          showError = !gemAgreeTC
        } else {
          showError = !gemAgreeTC || !ccManufaucture
        }
      }
    } else if (selectedPaymentOption === PaymentOptions.Afterpay) {
      if (this.props.deliveryType !== 1) {
        showError = !afterpayAgreeTC
      } else {
        showError = !afterpayAgreeTC || !ccManufaucture
      }
    } else if (selectedPaymentOption === PaymentOptions.DealerPaymentRequest){
      if (this.props.manufactureNow) {
        showError = !dealerPaymentRequestTC || !dealerTC
      } else {
        showError = !dealerPaymentRequestTC || !ccManufaucture || !dealerTC
      }
    }
    return showError
  }

  disablePaymentButton = () => {
    let disablePayment = false;
    const isAfterpayAvailable = this.props.basket.summary.itemTotal < 2000;
    const isDiscounted = this.props.basket.basketLines.filter(b => b.OriginalQuoteAmount ? b.OriginalQuoteAmount - b.priceInclGst > 0 : false ).length > 0
    const containsUpgradeRedesign = this.props.basket.basketLines.filter(b => b.transactionTypeId !== 1).length > 0
    if (this.state.paymentType == PaymentOptions.Account2Account) {
      if (containsUpgradeRedesign) {
        disablePayment = true;
      }
    } else if (this.state.paymentType == PaymentOptions.Afterpay) {
      disablePayment = this.state.disableAfterpayPayment;
    }
    return disablePayment;
  }

  render() {
    const { basket } = this.props
    const tcLabel = basket.basketLines.length > 1
      ? this.props.data.tcMO
      : this.props.data.tc;

    const isAfterpayAvailable = basket.summary.itemTotal < 2000;
    const orderHasPromo = basket.summary.discount !== 0
    const containsUpgradeRedesign = this.props.basket.basketLines.filter(b => b.transactionTypeId !== 1).length > 0
    const disablePaymentButton = this.disablePaymentButton();
    const checkout = getCurrentCheckout()
    return (
      <Grid>
        <Grid.Column width={16}>
          {/* LAYBY OPTION IS ONLY AVAILABLE FOR NEW AND SINGLE ORDER */}
          <SectionHeader>
            {this.props.isLaybySelected ? "Layby Payment" : "Select your Payment Method"}
          </SectionHeader>
        </Grid.Column>

        {this.props.isLaybySelected && (
          <LaybyPaymentContainer
            className={!this.props.isLaybySelected ? "hidden" : ""}
            width={16}
          >
            {
              this.props.laybyQuote != undefined &&
              <LaybyPaymentViewOnly
                apiUrl={this.props.apiUrl}
                basket={basket}
                laybyInfo={this.props.data.layby_info}
                laybyDetails={this.props.laybyQuote}
              ></LaybyPaymentViewOnly>
            }
          </LaybyPaymentContainer>
        )}

        <Grid.Column width={16}>
          {
            !this.props.isLaybySelected && (
              <>
                <Responsive as={Grid} columns={3} style={{ backgroundColor: 'lightgray', borderRadius: '5px' }} minWidth={theme.responsive.tablet.minWidth}>
                  <Grid.Column width={checkout.isDealerOrder ? 8 : 4}>
                    <PaymentOptionButton
                      onClick={() => this.switchPaymentType(PaymentOptions.CreditCard)}
                      active={this.state.paymentType === PaymentOptions.CreditCard}
                    >
                      Credit/Debit Card
                      {this.state.paymentType === PaymentOptions.CreditCard && <CheckoutSelectionCardIndicatorPayment>
                        <Icon name='check' size='small' />
                      </CheckoutSelectionCardIndicatorPayment>}
                    </PaymentOptionButton>
                  </Grid.Column>
                  {
                    checkout.isDealerOrder ?
                    <Grid.Column  width={8}>
                      <PaymentOptionButton
                        onClick={() => this.switchPaymentType(PaymentOptions.DealerPaymentRequest)}
                        active={this.state.paymentType === PaymentOptions.DealerPaymentRequest}
                      >
                        Dealer Payment Request
                      </PaymentOptionButton>
                    </Grid.Column>
                  :
                    <>
                      <Grid.Column>
                        <PaymentOptionButton
                          onClick={() => this.switchPaymentType(PaymentOptions.Account2Account)}
                          active={this.state.paymentType === PaymentOptions.Account2Account}
                        >
                          Account 2 Account
                          {this.state.paymentType === PaymentOptions.Account2Account && <CheckoutSelectionCardIndicatorPayment>
                            <Icon name='check' size='small' />
                          </CheckoutSelectionCardIndicatorPayment>}
                        </PaymentOptionButton>
                      </Grid.Column>
                      <Grid.Column>
                        <PaymentOptionButton
                          onClick={() => this.switchPaymentType(PaymentOptions.Afterpay)}
                          active={this.state.paymentType === PaymentOptions.Afterpay}
                          disabled={!isAfterpayAvailable || orderHasPromo}
                        >
                          <AfterpayLogo fill={"#000"} />
                          {this.state.paymentType === PaymentOptions.Afterpay && <CheckoutSelectionCardIndicatorPayment>
                            <Icon name='check' size='small' />
                          </CheckoutSelectionCardIndicatorPayment>}
                        </PaymentOptionButton>
                      </Grid.Column>
                    </>
                  }

                </Responsive>
                <Responsive as={GridColumn} width={16} maxWidth={theme.responsive.mobile.maxWidth} >


                  <PaymentOptionRadioButton fluid
                    onClick={() => this.switchPaymentType(PaymentOptions.CreditCard)}
                    active={this.state.paymentType === PaymentOptions.CreditCard}
                  >
                    <PaymentOptionRadio
                      checked={this.state.paymentType === PaymentOptions.CreditCard} />

                    <CDCard/>
                      {this.state.paymentType === PaymentOptions.CreditCard &&
                      <>
                        {checkout.isLayby ? (
                          <Grid.Column width={16} className="notes">
                            {this.props.data.cclaybynote}
                          </Grid.Column>
                        ) : (
                            <Grid.Column width={16} className="notes">{this.props.data.ccnote}</Grid.Column>
                        )}
                      </>}


                    {this.state.paymentType === PaymentOptions.CreditCard && <CheckoutSelectionCardIndicatorPayment>
                      <Icon name='check' size='small' />
                    </CheckoutSelectionCardIndicatorPayment>}
                  </PaymentOptionRadioButton>
                  {
                    checkout.isDealerOrder ?
                    <PaymentOptionRadioButton fluid
                      onClick={() => this.switchPaymentType(PaymentOptions.DealerPaymentRequest)}
                      active={this.state.paymentType === PaymentOptions.DealerPaymentRequest}
                    >
                      <PaymentOptionRadio
                        checked={this.state.paymentType === PaymentOptions.DealerPaymentRequest} />
                      Dealer Payment Request
                    </PaymentOptionRadioButton>
                    :
                    <>
                      {/* Gem */}
                      <PaymentOptionRadioButton fluid
                        onClick={() => this.switchPaymentType(PaymentOptions.Gem)}
                        active={this.state.paymentType === PaymentOptions.Gem}
                      >
                        <PaymentOptionRadio
                          checked={this.state.paymentType === PaymentOptions.Gem} />
                          <Gem/>

                          {this.state.paymentType === PaymentOptions.Gem &&
                          <>
                          {checkout.isLayby ? (
                            <Grid.Column width={16} className="notes">
                              {this.props.data.cclaybynote}
                            </Grid.Column>
                            ) : (
                            <Grid.Column width={16} className="notes">
                              {this.props.data.gemnote.replace(/<[^>]*>?/gm, '')}
                            </Grid.Column>
                          )}
                          </>}
                        {this.state.paymentType === PaymentOptions.Gem && <CheckoutSelectionCardIndicatorPayment>
                          <Icon name='check' size='small' />
                        </CheckoutSelectionCardIndicatorPayment>}
                      </PaymentOptionRadioButton>


                      {/* Afterpay */}
                      <PaymentOptionRadioButton fluid
                        onClick={() => this.switchPaymentType(PaymentOptions.Afterpay)}
                        active={this.state.paymentType === PaymentOptions.Afterpay}
                      >
                        <PaymentOptionRadio disabled={!isAfterpayAvailable || orderHasPromo} checked={this.state.paymentType === PaymentOptions.Afterpay && isAfterpayAvailable} />
                        <Afterpay />

                        {this.state.paymentType === PaymentOptions.Afterpay &&
                          <Grid.Column width={16} className="notes">
                          {disablePaymentButton ?
                          this.props.data.afterpay_disable_note:
                          this.props.data.afterpay_note}
                          </Grid.Column>
                        }

                        {this.state.paymentType === PaymentOptions.Afterpay && <CheckoutSelectionCardIndicatorPayment>
                          <Icon name='check' size='small' />
                        </CheckoutSelectionCardIndicatorPayment>}
                      </PaymentOptionRadioButton>


                      {/* aCCOUNT2aCCOUNT */}
                      <PaymentOptionRadioButton fluid
                        onClick={() => this.switchPaymentType(PaymentOptions.Account2Account)}
                        active={this.state.paymentType === PaymentOptions.Account2Account}
                      >
                        <PaymentOptionRadio
                          checked={this.state.paymentType === PaymentOptions.Account2Account} />
                        Account 2 Account
                        {this.state.paymentType === PaymentOptions.Account2Account &&
                        <>
                          {
                            disablePaymentButton ?
                              <Grid.Column width={16} className="notes">
                                <Paragraph1 dangerouslySetInnerHTML={{ __html: this.props.data.a2a_disable_note }} />
                              </Grid.Column>
                              :
                              (
                                checkout.isLayby ? (
                                  <Grid.Column width={16} className="notes">
                                    {this.props.data.aalaybynote}
                                  </Grid.Column>
                                ) : (
                                    <Grid.Column width={16} className="notes">
                                      {this.props.data.aanote}
                                    </Grid.Column>
                                  )
                              )
                          }
                        </>}
                        {this.state.paymentType === PaymentOptions.Account2Account && <CheckoutSelectionCardIndicatorPayment>
                          <Icon name='check' size='small' />
                        </CheckoutSelectionCardIndicatorPayment>}
                      </PaymentOptionRadioButton>
                    </>
                  }
                </Responsive>
              </>
            )
          }

          {this.props.isLaybySelected && (
            <Button.Group fluid>
              <PaymentOptionButton active={true}>
                Credit/Debit Card
              </PaymentOptionButton>
            </Button.Group>
          )}
        </Grid.Column>
        <div style={{color: theme.mobileTheme.color}}>
        {this.state.paymentType === PaymentOptions.CreditCard && (
          <Grid.Column width={16}>
            <Grid>
              {this.props.isLaybySelected ? (
                <Grid.Column width={16}>
                  {this.props.data.cclaybynote}
                </Grid.Column>
              ) : (
                  <Grid.Column width={16}>{this.props.data.ccnote}</Grid.Column>
                )}
              {this.props.deliveryType === 1 && (
                <Grid.Column width={16} className="t-and-c">
                  <TermsConditionsCheckbox
                    onChange={this.changeCCManufacture}
                    checked={this.state.ccManufaucture}
                  />
                  <div
                    dangerouslySetInnerHTML={{ __html: this.props.data.manutc }}
                  />
                </Grid.Column>
              )}
              {
                !checkout.isDealerOrder ?
                <Grid.Column width={16} className="t-and-c">
                  <TermsConditionsCheckbox
                    onChange={this.changeCCAgreeTC}
                    checked={this.state.ccAgreeTC}
                  />{" "}
                  <div dangerouslySetInnerHTML={{ __html: tcLabel }} />
                </Grid.Column>
                : <Grid.Column width={16} className="t-and-c">
                    <TermsConditionsCheckbox
                      onChange={this.changeDealerTC}
                      checked={this.state.dealerTC}
                    />
                    <div dangerouslySetInnerHTML={{ __html: this.props.data.dealers_t_c }} />
                  </Grid.Column>
              }

              {this.props.isLaybySelected && (
                <Grid.Column width={16}>
                  <Checkbox
                    disabled={true}
                    checked={true}
                    label="I hereby authorise KiwiPlates to deduct the remaining Layby payments (recurring payments), using the credit/debit card I will use now to make the deposit payment."
                  />
                </Grid.Column>
              )}
            </Grid>
          </Grid.Column>
        )}

        {this.state.paymentType === PaymentOptions.Gem && (
          <Grid.Column width={16}>
            <Grid>
              {this.props.deliveryType === 1 && (
                <Grid.Column width={16} className="t-and-c">
                  <TermsConditionsCheckbox
                    onChange={this.changeCCManufacture}
                    checked={this.state.ccManufaucture}
                  />
                  <div
                    dangerouslySetInnerHTML={{ __html: this.props.data.manutc }}
                  />
                </Grid.Column>
              )}
              <Grid.Column width={16} className="t-and-c">
                <TermsConditionsCheckbox
                  onChange={this.changeGemAgreeTC}
                  checked={this.state.gemAgreeTC}
                />
                <div dangerouslySetInnerHTML={{ __html: tcLabel }} />
              </Grid.Column>
            </Grid>
          </Grid.Column>
        )}

        {this.state.paymentType === PaymentOptions.Account2Account && (
          <Grid.Column width={16}>
            <Grid>
              {
                containsUpgradeRedesign ?
                  <Grid.Column width={16}>
                    <Paragraph1 dangerouslySetInnerHTML={{ __html: this.props.data.a2a_disable_note }} />
                  </Grid.Column>
                  :
                  (
                    this.props.isLaybySelected ? (
                      <Grid.Column width={16}>
                        {this.props.data.aalaybynote}
                      </Grid.Column>
                    ) : (
                        <Grid.Column width={16}>{this.props.data.aanote}</Grid.Column>
                      )
                  )
              }
              {this.props.deliveryType === 1 && (
                <Grid.Column width={16} className="t-and-c">
                  <TermsConditionsCheckbox
                    onChange={this.changeCCManufacture}
                    checked={this.state.ccManufaucture}
                  />
                  <div
                    dangerouslySetInnerHTML={{ __html: this.props.data.manutc }}
                  />
                </Grid.Column>
              )}
              <Grid.Column width={16} className="t-and-c">
                <TermsConditionsCheckbox
                  onChange={this.changeA2AAgreeTC}
                  checked={this.state.a2aAgreeTC}
                />
                <div dangerouslySetInnerHTML={{ __html: tcLabel }} />
              </Grid.Column>
            </Grid>
          </Grid.Column>
        )}
        {this.state.paymentType === PaymentOptions.Afterpay && (
          <Grid.Column width={16}>
            {
              isAfterpayAvailable ?
                <Grid>
                  <Grid.Column width={16}>{this.props.data.afterpay_note}</Grid.Column>
                  {this.props.deliveryType === 1 && (
                    <Grid.Column width={16} className="t-and-c">
                      <TermsConditionsCheckbox
                        onChange={this.changeCCManufacture}
                        checked={this.state.ccManufaucture}
                      />
                      <div
                        dangerouslySetInnerHTML={{ __html: this.props.data.manutc }}
                      />
                    </Grid.Column>
                  )}
                  <Grid.Column width={16} className="t-and-c">
                    <TermsConditionsCheckbox
                      onChange={this.changeAfterpayAgreeTC}
                      checked={this.state.afterpayAgreeTC}
                    />
                    <div dangerouslySetInnerHTML={{ __html: tcLabel }} />
                  </Grid.Column>
                </Grid>
                :
                <Grid>
                  <Grid.Column width={16}>
                    <Paragraph1 dangerouslySetInnerHTML={{ __html: this.props.data.afterpay_disable_note }} />
                  </Grid.Column>
                </Grid>
            }

          </Grid.Column>
        )}
        {this.state.paymentType === PaymentOptions.DealerPaymentRequest && (
          <Grid.Column width={16}>
            <Grid>
                <Grid.Column width={16}>{this.props.data.dealer_payment_request_note}</Grid.Column>
                {!checkout.manufactureNow && (
                <Grid.Column width={16} className="t-and-c">
                  <TermsConditionsCheckbox
                    onChange={this.changeCCManufacture}
                    checked={this.state.ccManufaucture}
                  />
                  <div
                    dangerouslySetInnerHTML={{ __html: this.props.data.manutc }}
                  />
                </Grid.Column>
              )}
              <Grid.Column width={16} className="t-and-c">
                <TermsConditionsCheckbox
                  onChange={this.changeDealerTC}
                  checked={this.state.dealerTC}
                />
                <div dangerouslySetInnerHTML={{ __html: this.props.data.dealers_t_c }} />
              </Grid.Column>
              <Grid.Column width={16} className="t-and-c">
                <TermsConditionsCheckbox
                  onChange={this.changeDealerPaymentRequestTC}
                  checked={this.state.dealerPaymentRequestTC}
                />{" "}
                <div dangerouslySetInnerHTML={{ __html: this.props.data.dealer_payment_request_t_c }} />
              </Grid.Column>
            </Grid>
          </Grid.Column>
        )}
        <Grid>
          {this.state.showError && (
            <Grid.Column width={16}>
              <Paragraph2 color="red">
                Please read and agree to the terms above before you can proceed
                to payment.
              </Paragraph2>
            </Grid.Column>
          )}
        </Grid>
        </div>
        <Grid.Column width={16}>
          <SummaryActionGrid reversed="mobile vertically">
            <SummaryActionColumn
              mobile={16}
              tablet={16}
              computer={16}
              largeScreen={16}
              widescreen={16}
              textAlign="right"
            ><br/>
              <KpButton
                id="fp-payment"
                fullWidth="mobile"
                disabled={this.state.processing || (disablePaymentButton && this.state.paymentType != PaymentOptions.Account2Account)}
                loading={this.state.processing}
                buttonType="primary"
                color={theme.mobileTheme.buttonBgColor}
                textColor={theme.mobileTheme.buttonColor}
                onClick={this.retryPayment}
              >
                {
                  this.state.paymentType == PaymentOptions.Afterpay ?
                    "PAY NOW WITH AFTERPAY" : (
                      (this.state.paymentType == PaymentOptions.Account2Account && containsUpgradeRedesign
                        || this.state.paymentType === PaymentOptions.DealerPaymentRequest) ? "PLACE ORDER" : "PAY NOW"
                    )
                }
              </KpButton>
              {this.state.processing && (
                <div>
                  <Legal color="#707070">Loading Please wait</Legal>
                </div>
              )}
            </SummaryActionColumn>
            {this.state.error !== "" && (
              <InfoModal
                modalOpen={true}
                title="ERROR"
                content={this.state.error}
                onClose={this.state.onErrorModalClose}
              />
            )}
          </SummaryActionGrid>
        </Grid.Column>
      </Grid>
    )
  }
}


export default MobileRetryPayment